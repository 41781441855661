import {
  Box,
  Typography,
  Card,
  CardHeader,
  CardContent,
  Stack,
  Link,
  Button,
} from "@mui/material"
import { useNavigate, useParams } from "react-router-dom"
import { useRecoilValue } from "recoil"

import { Notice, PutNoticeRequestStatusEnum } from "src/api/models"
import { getNotice, getNotices } from "src/api/notices"
import { OutlinedMenuButton } from "src/components/atoms/OutlinedMenuButton"
import { MainContentLayout } from "src/components/templates/MainContentLayout"
import { useResource } from "src/hooks/useResource"
import { useSubmitting } from "src/hooks/useSubmitting"
import { useUserRole } from "src/hooks/useUserRole"
import { currentArcadeState } from "src/recoil"
import { getJpDateLabel } from "src/utils"

export const Arcade: React.FC = () => {
  const currentArcade = useRecoilValue(currentArcadeState)
  const { isAdmin } = useUserRole()
  const navigate = useNavigate()
  return (
    <>
      {currentArcade && (
        <MainContentLayout
          title={currentArcade.name}
          renderContent={() => (
            <Stack gap={2}>
              {isAdmin && <NoticeList />}
              <OperationMenu />
            </Stack>
          )}
          onClickBackButton={() => navigate("/my-page")}
        />
      )}
    </>
  )
}

export const NoticeList: React.FC = () => {
  const { arcadeCd } = useParams()
  const navigate = useNavigate()

  const noticesResource = useResource({
    subject: "お知らせ詳細の取得",
    fetch: () => getNotices(),
    recoilKey: `getNotices`,
  }).resource
  const notices = (noticesResource?.data.notices || [])
    .filter((notice) => notice.status === PutNoticeRequestStatusEnum.Public)
    .sort((a: Notice, b: Notice) => {
      if (a.openAt && b.openAt) {
        return a.openAt < b.openAt ? 1 : -1
      }
      return 0
    })

  const { submitPromises } = useSubmitting()
  const onClickNotice = async (id: number) => {
    await submitPromises([
      {
        subject: "お知らせの取得",
        showSuccessMessage: false,
        promise: async () => {
          const res = await getNotice(id)
          const notice = res.data.notice
          if (notice.url) {
            window.open(notice.url, "_blank")
          }
        },
      },
    ])
  }

  return (
    <Card sx={{ px: 2, pt: 3 }}>
      <CardHeader title="お知らせ" sx={{ p: 0 }} />
      <CardContent sx={{ p: 0, pt: 2 }}>
        <Stack gap={2}>
          {notices.length === 0 && (
            <Typography>現在お知らせはありません</Typography>
          )}
          {notices.slice(0, 3).map((notice) => (
            <Stack
              key={notice.id}
              alignItems="flex-start"
              justifyContent="flex-start"
              gap={0.5}
            >
              <Typography variant="caption" color="text.secondary">
                {getJpDateLabel(notice.openAt)}
              </Typography>

              <Link
                component="button"
                variant="subtitle1"
                underline="none"
                onClick={() => onClickNotice(notice.id)}
              >
                {notice.title}
              </Link>
            </Stack>
          ))}
          {notices.length > 3 && (
            <Button
              variant="outlined"
              onClick={() => navigate(`/arcades/${arcadeCd}/notices`)}
            >
              もっと見る
            </Button>
          )}
        </Stack>
      </CardContent>
    </Card>
  )
}

const OperationMenu: React.FC = () => {
  const { arcadeCd } = useParams()
  return (
    <Card sx={{ p: 3, pb: 0 }}>
      <CardHeader title="業務メニュー" sx={{ p: 0 }} />
      <CardContent sx={{ p: 0, pt: 0 }}>
        <Box sx={{ display: "flex", gap: 2, mt: 2 }}>
          <OutlinedMenuButton to={`/arcades/${arcadeCd}/prizes`}>
            <Typography variant="subtitle1">景品業務</Typography>
          </OutlinedMenuButton>
          <OutlinedMenuButton to={`/arcades/${arcadeCd}/materials`}>
            <Typography variant="subtitle1">材料業務</Typography>
          </OutlinedMenuButton>
        </Box>
        <Box sx={{ display: "flex", mt: 3 }}>
          <OutlinedMenuButton to={`/arcades/${arcadeCd}/inventory`}>
            棚卸業務
          </OutlinedMenuButton>
        </Box>
      </CardContent>
    </Card>
  )
}
