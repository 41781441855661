import {
  useMemo,
  useEffect,
  useRef,
  useImperativeHandle,
  forwardRef,
} from "react"

import {
  Button,
  MenuItem,
  Select,
  Stack,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material"
import { useParams } from "react-router-dom"
import { useRecoilState, useRecoilValue } from "recoil"

import { getMaterialInventoryApprovals } from "src/api/material-inventory-differences"
import {
  ExecutionPeriod,
  FeatureExecutionPeriod,
  MaterialInventoryApproval,
  PrizeInventoryApproval,
} from "src/api/models"
import { getPrizeInventoryApprovals } from "src/api/prize-inventory-differences"
import { ExtTableCell } from "src/components/atoms/ExtTableCell"
import {
  CardItemNameBox,
  TableBorderedRow,
} from "src/components/molecules/CardTableCells"
import { FilterAccordion } from "src/components/molecules/FilterAccordion"
import { PaginatedTable } from "src/components/organisms/PaginatedTable"
import { MainContentLayout } from "src/components/templates/MainContentLayout"
import {
  approvalTableHeaders,
  getInventoryApprovalLabel,
  approvalTableKeys,
} from "src/domains/inventoryApprovalRepository"
import { executionPeriodTentativeEndAt } from "src/domains/inventoryExecutionPeriodRepository"
import { useDownloadCsv } from "src/hooks/useDownloadCsv"
import { useResource } from "src/hooks/useResource"
import { filterAccordionSearchState, inventoryPeriodsState } from "src/recoil"
import { emptyValueNormalizedDeepEqual } from "src/utils"

type SearchParams = {
  executionPeriodId: ExecutionPeriod["id"] | undefined
  kind: string
}

const itemNameGradation =
  "-webkit-gradient(linear, 95% 0%, 98% 0%, from(rgba(0,0,0,1)), to(rgba(0,0,0,0)))"

const defaultSearchParams: SearchParams = {
  executionPeriodId: undefined,
  kind: "all",
}

export const InventoryDifferenceApprovals: React.FC = () => {
  const [recoilSearchParams, setRecoilSearchParams] = useRecoilState(
    filterAccordionSearchState,
  )
  const searchParams =
    recoilSearchParams["inventoryDifferenceApprovalsSearchParams"] ??
    defaultSearchParams

  // NOTE: 棚卸確定未済 = 終了日が 2200-12-31 の棚卸期間は除外
  const defaultExecutionPeriods = useRecoilValue(inventoryPeriodsState)
  const executionPeriods = useMemo(
    () =>
      (defaultExecutionPeriods || []).filter(
        (period) => period.endAt !== executionPeriodTentativeEndAt,
      ),
    [defaultExecutionPeriods],
  )
  const selectedExecutionPeriod = (executionPeriods || []).find(
    (period) => period.id === searchParams.executionPeriodId,
  )
  const selectedPrizeExecutionPeriod =
    selectedExecutionPeriod?.prizeExecutionPeriods[0]
  const selectedMaterialExecutionPeriod =
    selectedExecutionPeriod?.materialExecutionPeriods[0]

  useEffect(
    () =>
      executionPeriods &&
      setRecoilSearchParams((prev) => ({
        ...prev,
        inventoryDifferenceApprovalsSearchParams: {
          executionPeriodId: executionPeriods[0]?.id,
          kind: "all",
        },
      })),
    [executionPeriods, setRecoilSearchParams],
  )

  const ref = useRef<CsvDownloadHandler>(null)

  return (
    <MainContentLayout
      title="戻しが10万円以上の申請一覧"
      renderAction={() => (
        <Button variant="contained" onClick={() => ref.current?.download()}>
          過去の申請CSV出力
        </Button>
      )}
      renderFilter={() => (
        <FilterAccordion
          searchParams={searchParams}
          setSearchParams={(params) =>
            setRecoilSearchParams((prev) => ({
              ...prev,
              inventoryDifferenceApprovalsSearchParams: params,
            }))
          }
          accordionLabel="絞り込み"
          defaultExpanded={
            !emptyValueNormalizedDeepEqual(searchParams, defaultSearchParams)
          }
          formInputs={[
            {
              name: "executionPeriodId",
              label: "期間",
              render: ({ field }) => (
                <Select
                  {...field}
                  value={field.value || searchParams.executionPeriodId || ""}
                  fullWidth
                >
                  {executionPeriods?.map(({ id, startAt, endAt }) => (
                    <MenuItem key={`${startAt}〜${endAt}`} value={id}>
                      {startAt}〜
                      {endAt !== executionPeriodTentativeEndAt && endAt}
                    </MenuItem>
                  ))}
                </Select>
              ),
            },
            {
              name: "kind",
              label: "種別",
              render: ({ field }) => (
                <Select {...field} fullWidth>
                  <MenuItem value="all">すべて</MenuItem>
                  <MenuItem value="prize">景品</MenuItem>
                  <MenuItem value="material">材料</MenuItem>
                </Select>
              ),
            },
          ]}
        />
      )}
      renderContent={() => (
        <InventoryDifferenceApprovalsInner
          ref={ref}
          selectedExecutionPeriod={selectedExecutionPeriod}
          selectedPrizeExecutionPeriod={selectedPrizeExecutionPeriod}
          selectedMaterialExecutionPeriod={selectedMaterialExecutionPeriod}
        />
      )}
    />
  )
}

interface CsvDownloadHandler {
  download: () => void
}

type InventoryDifferenceApprovalsInnerProps = {
  selectedExecutionPeriod?: ExecutionPeriod
  selectedPrizeExecutionPeriod?: FeatureExecutionPeriod
  selectedMaterialExecutionPeriod?: FeatureExecutionPeriod
}

const InventoryDifferenceApprovalsInner = forwardRef<
  CsvDownloadHandler,
  InventoryDifferenceApprovalsInnerProps
>(
  (
    {
      selectedExecutionPeriod,
      selectedPrizeExecutionPeriod,
      selectedMaterialExecutionPeriod,
    },
    ref,
  ) => {
    const { arcadeCd } = useParams()
    const searchParams =
      useRecoilValue(filterAccordionSearchState)[
        "inventoryDifferenceApprovalsSearchParams"
      ] ?? defaultSearchParams

    const { resource: prizeApprovalsResource } = useResource({
      subject: "景品の差分申請一覧の取得",
      fetch:
        arcadeCd && selectedPrizeExecutionPeriod
          ? () =>
              getPrizeInventoryApprovals(
                arcadeCd,
                selectedPrizeExecutionPeriod.id,
              )
          : undefined,
      skip: selectedPrizeExecutionPeriod === undefined,
      recoilKey: `getPrizeInventoryApprovals:${arcadeCd}:${JSON.stringify(
        selectedPrizeExecutionPeriod,
      )}`,
    })
    const prizeApprovals = prizeApprovalsResource?.data.approvals || []
    const { resource: materialApprovalsResource } = useResource({
      subject: "材料の差分申請一覧の取得",
      fetch:
        arcadeCd && selectedMaterialExecutionPeriod
          ? () =>
              getMaterialInventoryApprovals(
                arcadeCd,
                selectedMaterialExecutionPeriod.id,
              )
          : undefined,
      skip: selectedMaterialExecutionPeriod === undefined,
      recoilKey: `getMaterialInventoryApprovals:${arcadeCd}:${JSON.stringify(
        selectedMaterialExecutionPeriod,
      )}`,
    })
    const materialApprovals = materialApprovalsResource?.data.approvals || []
    const approvals: Array<PrizeInventoryApproval | MaterialInventoryApproval> =
      [...prizeApprovals, ...materialApprovals]
    const filteredApprovals: Array<
      PrizeInventoryApproval | MaterialInventoryApproval
    > =
      approvals?.filter((approval) => {
        if (searchParams.kind === "all") return true

        const label = getInventoryApprovalLabel(arcadeCd, approval)
        return (
          (searchParams.kind === "prize" && label.kind === "景品") ||
          (searchParams.kind === "material" && label.kind === "材料")
        )
      }) ?? []

    const getLines = (itemName: string[]) =>
      itemName.map((line, i) => <p key={`${i}-${line}`}>{line}</p>)

    const { downloadCsv } = useDownloadCsv()
    const onSubmit = () => {
      if (!arcadeCd) return
      if (!selectedExecutionPeriod) return
      if (filteredApprovals.length === 0) return

      const headerRow = {
        columns: approvalTableKeys.map((key) =>
          approvalTableHeaders[key].label.join(""),
        ),
      }
      const rows = filteredApprovals.map((approval) => {
        const label = getInventoryApprovalLabel(arcadeCd, approval)
        return {
          columns: approvalTableKeys.map((key) => {
            const column = label[key]
            if (key === "application") {
              return column.replace(/\n/g, " ").replace(/,/g, "，")
            }
            return column.replace(/[,\n]/g, "")
          }),
        }
      })

      const csv = {
        headerRow,
        rows,
      }

      downloadCsv({
        csv,
        fileName: `inventory_difference_approvals_${arcadeCd}_${selectedExecutionPeriod.startAt}_${selectedExecutionPeriod.endAt}.csv`,
      })
    }

    const stackRef = useRef<HTMLDivElement>(null)
    useImperativeHandle(
      ref,
      () => ({
        download() {
          stackRef.current?.click()
        },
      }),
      [],
    )

    return (
      <Stack>
        <PaginatedTable
          scrollableX
          noMargin
          items={filteredApprovals}
          stateKey="inventoryDifferenceApprovalsTable"
          header={
            <TableHead>
              <TableRow
                sx={{
                  th: { px: 1, whiteSpace: "nowrap", textAlign: "center" },
                }}
              >
                <ExtTableCell sx={{ minWidth: 320, borderRight: "1px solid" }}>
                  {getLines(approvalTableHeaders.itemName.label)}
                </ExtTableCell>
                <ExtTableCell sx={{ minWidth: 100 }}>
                  {getLines(approvalTableHeaders.kind.label)}
                </ExtTableCell>
                <ExtTableCell>申請店舗CD</ExtTableCell>
                <ExtTableCell sx={{ minWidth: 160 }}>
                  {getLines(approvalTableHeaders.application.label)}
                </ExtTableCell>
                <ExtTableCell sx={{ minWidth: 120 }}>
                  {getLines(approvalTableHeaders.appliedAt.label)}
                </ExtTableCell>
                <ExtTableCell>
                  {getLines(approvalTableHeaders.appliedBy.label)}
                </ExtTableCell>
                <ExtTableCell sx={{ minWidth: 120 }}>
                  {getLines(approvalTableHeaders.approvedAt.label)}
                </ExtTableCell>
                <ExtTableCell>
                  {getLines(approvalTableHeaders.approvedBy.label)}
                </ExtTableCell>
                <ExtTableCell>
                  {getLines(approvalTableHeaders.itemCd.label)}
                </ExtTableCell>
                <ExtTableCell>
                  {getLines(approvalTableHeaders.importedStock.label)}
                </ExtTableCell>
                <ExtTableCell>
                  {getLines(approvalTableHeaders.stock.label)}
                </ExtTableCell>
                <ExtTableCell>
                  {getLines(approvalTableHeaders.payoutStock.label)}
                </ExtTableCell>
                <ExtTableCell>
                  {getLines(approvalTableHeaders.consumedPrice.label)}
                </ExtTableCell>
                <ExtTableCell>
                  {getLines(approvalTableHeaders.isInSeams.label)}
                </ExtTableCell>
                <ExtTableCell>
                  {getLines(approvalTableHeaders.isInGigoNavi.label)}
                </ExtTableCell>
                <ExtTableCell>
                  {getLines(approvalTableHeaders.differentPrice.label)}
                </ExtTableCell>
              </TableRow>
            </TableHead>
          }
          renderRow={(approval) => {
            const label = getInventoryApprovalLabel(arcadeCd, approval)
            return (
              <TableBorderedRow
                sx={{
                  td: {
                    px: 1,
                    py: 2,
                    textAlign: "center",
                  },
                }}
              >
                <ExtTableCell
                  sx={{
                    textAlign: "start !important",
                    minWidth: 320,
                    maxWidth: 320,
                    whiteSpace: "nowrap",
                    WebkitMaskImage: itemNameGradation,
                    borderRight: "1px solid",
                  }}
                >
                  <CardItemNameBox>
                    <Typography variant="body2">{label.itemName}</Typography>
                  </CardItemNameBox>
                </ExtTableCell>
                <ExtTableCell sx={{ minWidth: 100, maxWidth: 100 }}>
                  {label.kind}
                </ExtTableCell>
                <ExtTableCell>{arcadeCd}</ExtTableCell>
                <ExtTableCell sx={{ minWidth: 160, maxWidth: 160 }}>
                  <CardItemNameBox>
                    <Typography variant="body2">{label.application}</Typography>
                  </CardItemNameBox>
                </ExtTableCell>
                <ExtTableCell sx={{ minWidth: 120, maxWidth: 120 }}>
                  {label.appliedAt}
                </ExtTableCell>
                <ExtTableCell>{label.appliedBy}</ExtTableCell>
                <ExtTableCell sx={{ minWidth: 120, maxWidth: 120 }}>
                  {label.approvedAt}
                </ExtTableCell>
                <ExtTableCell>{label.approvedBy}</ExtTableCell>
                <ExtTableCell>{label.itemCd}</ExtTableCell>
                <ExtTableCell>{label.importedStock}</ExtTableCell>
                <ExtTableCell>{label.stock}</ExtTableCell>
                <ExtTableCell>{label.payoutStock}</ExtTableCell>
                <ExtTableCell>{label.consumedPrice}</ExtTableCell>
                <ExtTableCell>{label.isInSeams}</ExtTableCell>
                <ExtTableCell>{label.isInGigoNavi}</ExtTableCell>
                <ExtTableCell>{label.differentPrice}</ExtTableCell>
              </TableBorderedRow>
            )
          }}
        />
        <Stack onClick={onSubmit} ref={stackRef} hidden />
      </Stack>
    )
  },
)
InventoryDifferenceApprovalsInner.displayName =
  "InventoryDifferenceApprovalsInner"
