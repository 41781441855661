import { TextField, MenuItem, Select } from "@mui/material"
import { useRecoilState } from "recoil"

import { PrizeStock } from "src/api/models"
import { FilterAccordion } from "src/components/molecules/FilterAccordion"
import { StockSearchParams } from "src/pages/prizes/search/Stocks" // TODO: 型定義の場所を整理する
import { stockSearchParamsState } from "src/recoil"
import {
  compareByPhoneticOrder,
  emptyValueNormalizedDeepEqual,
  generateFuzzyRegExp,
} from "src/utils"

const sortByLabels = {
  publishedAtNewer: "発売日新しい順",
  publishedAtOlder: "発売日古い順",
  phoneticOrderAsc: "あいうえお順",
  phoneticOrderDesc: "あいうえお順（降順）",
}

export const StockFilter: React.FC = () => {
  const [searchParams, setSearchParams] = useRecoilState(stockSearchParamsState)

  return (
    <FilterAccordion
      searchParams={searchParams}
      setSearchParams={setSearchParams}
      accordionLabel="絞り込み・並び替え"
      defaultExpanded={!emptyValueNormalizedDeepEqual(searchParams, {})}
      formInputs={[
        {
          name: "prizeCd",
          label: "景品CD",
          render: ({ field, fieldState: { error } }) => (
            <TextField {...field} error={!!error} />
          ),
        },
        {
          name: "prizeName",
          label: "景品名",
          render: ({ field, fieldState: { error } }) => (
            <TextField {...field} error={!!error} />
          ),
        },
        {
          name: "makerName",
          label: "メーカー名",
          render: ({ field, fieldState: { error } }) => (
            <TextField id={field.name} {...field} error={!!error} />
          ),
        },
        {
          name: "ipName",
          label: "IP名",
          render: ({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              error={!!error}
              placeholder="カタカナ部分一致"
            />
          ),
        },

        {
          name: "sortBy",
          label: "並び替え",
          render: ({ field }) => (
            <Select {...field} value={field.value ?? ""}>
              <MenuItem key="" value="" sx={{ height: 36 }} />
              {Object.entries(sortByLabels).map(([key, value]) => (
                <MenuItem key={key} value={key}>
                  {value}
                </MenuItem>
              ))}
            </Select>
          ),
        },
      ]}
    />
  )
}

export const filterStocks = (
  stocks: PrizeStock[],
  params: StockSearchParams,
) => {
  const { prizeName, prizeCd, makerName, ipName, sortBy } = params

  const getSortCompareBy = () => {
    if (sortBy === "publishedAtNewer") {
      return (a: PrizeStock, b: PrizeStock) =>
        (a.publishedAt || "") <= (b.publishedAt || "") ? 1 : -1
    }
    if (sortBy === "publishedAtOlder") {
      return (a: PrizeStock, b: PrizeStock) =>
        (a.publishedAt || "") >= (b.publishedAt || "") ? 1 : -1
    }
    if (sortBy === "phoneticOrderAsc") {
      return (a: PrizeStock, b: PrizeStock) =>
        compareByPhoneticOrder(a.prize.prizeName, b.prize.prizeName)
    }
    if (sortBy === "phoneticOrderDesc") {
      return (a: PrizeStock, b: PrizeStock) =>
        compareByPhoneticOrder(b.prize.prizeName, a.prize.prizeName)
    }
    return () => 1
  }

  return stocks
    .filter((s) =>
      prizeName ? generateFuzzyRegExp(prizeName).test(s.prize.prizeName) : true,
    )
    .filter((s) =>
      prizeCd ? generateFuzzyRegExp(prizeCd).test(s.prize.prizeCd) : true,
    )
    .filter((s) =>
      makerName ? generateFuzzyRegExp(makerName).test(s.prize.makerName) : true,
    )
    .filter((s) =>
      ipName ? generateFuzzyRegExp(ipName).test(s.prize.ipName) : true,
    )
    .sort(getSortCompareBy())
}
