import { TextField, MenuItem, Select } from "@mui/material"
import { atom, useRecoilState } from "recoil"

import { FilterAccordion } from "src/components/molecules/FilterAccordion"
import { materialStockLedgerPlacementsSortByLabels } from "src/domains/materials/materialStockLedgerRepository"
import { emptyValueNormalizedDeepEqual } from "src/utils"

export const materialStockLedgerPlacementsSearchParamsState =
  atom<MaterialStockLedgerPlacementsSearchParams>({
    key: "materialStockLedgerPlacementsSearchParamsState",
    default: {},
  })

export type MaterialStockLedgerPlacementsSearchParams = {
  placementName?: string
  sortBy?: keyof typeof materialStockLedgerPlacementsSortByLabels
}

export const InventoryMaterialStockLedgerPlacementsFilter: React.FC = () => {
  const [searchParams, setSearchParams] = useRecoilState(
    materialStockLedgerPlacementsSearchParamsState,
  )

  return (
    <FilterAccordion
      searchParams={searchParams}
      setSearchParams={setSearchParams}
      accordionLabel="絞り込み・並び替え"
      defaultExpanded={!emptyValueNormalizedDeepEqual(searchParams, {})}
      formInputs={[
        {
          name: "placementName",
          label: "保管場所名",
          render: ({ field, fieldState: { error } }) => (
            <TextField {...field} error={!!error} helperText={error?.message} />
          ),
        },
        {
          name: "sortBy",
          label: "並び替え",
          render: ({ field }) => (
            <Select {...field} value={field.value ?? ""}>
              <MenuItem key="" value="" sx={{ height: 36 }} />
              {Object.entries(materialStockLedgerPlacementsSortByLabels).map(
                ([key, value]) => (
                  <MenuItem key={key} value={key}>
                    {value}
                  </MenuItem>
                ),
              )}
            </Select>
          ),
        },
      ]}
    />
  )
}
