import React from "react"

import { Typography, Card, Stack, Link } from "@mui/material"

import { Notice, PutNoticeRequestStatusEnum } from "src/api/models"
import { getNotice, getNotices } from "src/api/notices"
import { SeeMore } from "src/components/organisms/SeeMore"
import { MainContentLayout } from "src/components/templates/MainContentLayout"
import { useResource } from "src/hooks/useResource"
import { useSubmitting } from "src/hooks/useSubmitting"
import { getJpDateLabel } from "src/utils"

export const UserNoticeList: React.FC = () => {
  return (
    <MainContentLayout
      title="お知らせ"
      renderContent={() => <UserNoticeListInner />}
    />
  )
}

const UserNoticeListInner: React.FC = () => {
  const noticesResource = useResource({
    subject: "お知らせ詳細の取得",
    fetch: () => getNotices(),
    recoilKey: `getNotices`,
  }).resource
  const notices = (noticesResource?.data.notices || [])
    .filter((notice) => notice.status === PutNoticeRequestStatusEnum.Public)
    .sort((a: Notice, b: Notice) => {
      if (a.openAt && b.openAt) {
        return a.openAt < b.openAt ? 1 : -1
      }
      return 0
    })

  const { submitPromises } = useSubmitting()
  const onClickNotice = async (id: number) => {
    await submitPromises([
      {
        subject: "お知らせの取得",
        showSuccessMessage: false,
        promise: async () => {
          const res = await getNotice(id)
          const notice = res.data.notice
          if (notice.url) {
            window.open(notice.url, "_blank")
          }
        },
      },
    ])
  }

  return (
    <Stack gap={2}>
      <Card sx={{ px: 2, py: 3 }}>
        <Stack gap={2}>
          <SeeMore
            items={notices}
            itemCountPerShow={10}
            renderWrapper={(renderItems) => (
              <Stack gap={2}>{renderItems()}</Stack>
            )}
            renderItem={(notice) => (
              <Stack
                key={notice.id}
                alignItems="flex-start"
                justifyContent="flex-start"
                gap={0.5}
              >
                <Typography variant="caption" color="text.secondary">
                  {getJpDateLabel(notice.openAt)}
                </Typography>

                <Link
                  component="button"
                  variant="subtitle1"
                  underline="none"
                  onClick={() => onClickNotice(notice.id)}
                >
                  {notice.title}
                </Link>
              </Stack>
            )}
          />
        </Stack>
      </Card>
    </Stack>
  )
}
