/* eslint-disable */
/**
 * Generated by orval v7.0.1 🍺
 * Do not edit manually.
 * u1-api
 * OpenAPI spec version: 1.0.0
 */

/**
 * GigoNaviに景品が存在するかどうか
 */
export type PrizeInventoryEstimationGroupEnum =
  (typeof PrizeInventoryEstimationGroupEnum)[keyof typeof PrizeInventoryEstimationGroupEnum]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PrizeInventoryEstimationGroupEnum = {
  Valid: "valid",
  InvalidPrizeCd: "invalid_prize_cd",
  NotInSeams: "not_in_seams",
} as const
