import React, { SetStateAction, useMemo } from "react"

import { OpenInNew } from "@mui/icons-material"
import WarningAmberIcon from "@mui/icons-material/WarningAmber"
import {
  Typography,
  Stack,
  TableHead,
  TableRow,
  Link,
  Card,
  TextField,
  Button,
  IconButton,
  Dialog,
  Chip,
  Select,
  MenuItem,
} from "@mui/material"
import dayjs from "dayjs"
import isBetween from "dayjs/plugin/isBetween"
import { Link as RouterLink, useParams } from "react-router-dom"
import { atom, useRecoilState, useRecoilValue } from "recoil"

import { getPrizeDeliveries } from "src/api/prize-deliveries"
import { getPrizeDailyPlans } from "src/api/prize-plans"
import { getPrizeBoothSales } from "src/api/prize-sales"
import {
  DateRangePicker,
  DateRangePickerDateLabel,
} from "src/components/atoms/DateRangePicker"
import { ExtTableCell } from "src/components/atoms/ExtTableCell"
import { FilterAccordion } from "src/components/molecules/FilterAccordion"
import { DatePicker } from "src/components/organisms/DatePicker"
import { PaginatedTable } from "src/components/organisms/PaginatedTable"
import { MainContentLayout } from "src/components/templates/MainContentLayout"
import {
  PrizeDailySale,
  calcPrizeDailySales,
} from "src/domains/prizes/prizeSalesRepository"
import { useDownloadCsv } from "src/hooks/useDownloadCsv"
import { useResource } from "src/hooks/useResource"
import { useUserRole } from "src/hooks/useUserRole"
import { filterAccordionSearchState } from "src/recoil"
import { DateLabelString } from "src/types"
import {
  emptyValueNormalizedDeepEqual,
  formatApiDate,
  getDaysAgo,
  getJpDateLabel,
  getMonthEnd,
  getMonthStart,
  getRatioLabel,
  getYesterday,
  isBetweenDateRangeLabel,
} from "src/utils"

dayjs.extend(isBetween)

type PrizeDailySalesSelectedDate = DateLabelString

const prizeDailySalesSelectedDateState = atom<PrizeDailySalesSelectedDate>({
  key: "prizeDailySalesSelectedDateState",
  default: getYesterday(),
})

export const PrizeDailySales: React.FC = () => {
  const { arcadeCd } = useParams()
  const [selectedDate, setSelectedDate] = useRecoilState(
    prizeDailySalesSelectedDateState,
  )

  return (
    <MainContentLayout
      title="日次プライズ実績"
      renderAction={() => (
        <Button
          variant="contained"
          endIcon={<OpenInNew />}
          onClick={() =>
            window.open(
              `/arcades/${arcadeCd}/prizes/sales/booth?${new URLSearchParams({ selected_date: selectedDate })}`,
              "_blank",
            )
          }
        >
          ブース別の日ごと実績一覧
        </Button>
      )}
      renderFilter={() => (
        <Stack gap={2}>
          <Card sx={{ p: 2 }}>
            <DatePicker
              value={selectedDate}
              onChange={(v) => setSelectedDate(v)}
              format={"YYYY-MM-DD"}
              views={["year", "month", "day"]}
              openTo="day"
            />
          </Card>
          <Stack>
            <PrizeDailySalesFilter />
          </Stack>
        </Stack>
      )}
      renderContent={() => <PrizeDailySalesInner />}
    />
  )
}

const machineInputDateRangeMethodLabels = {
  all: "全期間",
  specified: "期間指定",
} as const

type PrizeDailySalesSearchParams = {
  boothName?: string
  boothCategory?: string
  prizeName?: string
  prizeCd?: string
  pdpbRatio?: {
    min: number
    max: number
  }
  machineInputDateRangeMethod: keyof typeof machineInputDateRangeMethodLabels
  machineInputDateRange: Partial<DateRangePickerDateLabel>
}

const defaultSearchParams: PrizeDailySalesSearchParams = {
  machineInputDateRangeMethod: "all",
  machineInputDateRange: {
    start: getDaysAgo(8),
    end: getYesterday(),
  },
}

const PrizeDailySalesFilter: React.FC = () => {
  const [recoilSearchParams, setRecoilSearchParams] = useRecoilState(
    filterAccordionSearchState,
  )

  const searchParams =
    recoilSearchParams["prizeDailySalesSearchParams"] ?? defaultSearchParams
  const setSearchParams = (
    params: SetStateAction<PrizeDailySalesSearchParams>,
  ) =>
    setRecoilSearchParams((prev) => ({
      ...prev,
      prizeDailySalesSearchParams: params,
    }))

  return (
    <FilterAccordion
      searchParams={searchParams}
      setSearchParams={setSearchParams}
      accordionLabel="絞り込み"
      defaultExpanded={
        !emptyValueNormalizedDeepEqual(searchParams, defaultSearchParams)
      }
      formInputs={[
        {
          name: "boothName",
          label: "プライズ機種名（ブース名）",
          render: ({ field, fieldState: { error } }) => (
            <TextField {...field} error={!!error} fullWidth />
          ),
        },
        {
          name: "prizeName",
          label: "景品名",
          render: ({ field, fieldState: { error } }) => (
            <TextField {...field} error={!!error} fullWidth />
          ),
        },
        {
          name: "boothCategory",
          label: "ブース区分",
          render: ({ field, fieldState: { error } }) => (
            <TextField {...field} error={!!error} fullWidth />
          ),
        },
        {
          name: "prizeCd",
          label: "景品CD",
          render: ({ field, fieldState: { error } }) => (
            <TextField {...field} error={!!error} fullWidth />
          ),
        },
        {
          name: "pdpbRatio",
          fullWidth: true,
          label: "対ブース平均",
          render: ({ field }) => (
            <Stack direction="row" alignItems="center" gap={2} sx={{ flex: 1 }}>
              <Stack
                direction="row"
                alignItems="center"
                gap={1}
                sx={{ flex: 1 }}
              >
                <TextField
                  type="number"
                  value={
                    (field.value as PrizeDailySalesSearchParams["pdpbRatio"])
                      ?.min
                  }
                  onChange={(e) =>
                    field.onChange({
                      ...(field.value as PrizeDailySalesSearchParams["pdpbRatio"]),
                      min: e.target.value,
                    })
                  }
                  sx={{ flex: 1 }}
                />
                <Typography component="label">%</Typography>
              </Stack>
              <Typography component="label">〜</Typography>
              <Stack
                direction="row"
                alignItems="center"
                gap={1}
                sx={{ flex: 1 }}
              >
                <TextField
                  type="number"
                  value={
                    (field.value as PrizeDailySalesSearchParams["pdpbRatio"])
                      ?.max
                  }
                  onChange={(e) =>
                    field.onChange({
                      ...(field.value as PrizeDailySalesSearchParams["pdpbRatio"]),
                      max: e.target.value,
                    })
                  }
                  sx={{ flex: 1 }}
                />
                <Typography component="label">%</Typography>
              </Stack>
            </Stack>
          ),
        },
        {
          name: "machineInputDateRangeMethod",
          label: "投入可能日",
          render: ({ field }) => (
            <Select {...field} fullWidth>
              {Object.entries(machineInputDateRangeMethodLabels).map(
                ([key, value]) => (
                  <MenuItem key={key} value={key}>
                    {value}
                  </MenuItem>
                ),
              )}
            </Select>
          ),
        },
        {
          name: "machineInputDateRange",
          render: ({ field }) => (
            <DateRangePicker
              dateRangeLabel={field.value as DateRangePickerDateLabel}
              setDateRangeLabel={field.onChange}
            />
          ),
          hiddenAt: (values) =>
            values["machineInputDateRangeMethod"] !== "specified",
        },
      ]}
    />
  )
}

const PrizeDailySalesInner: React.FC = () => {
  const { arcadeCd } = useParams()
  const selectedDate = useRecoilValue(prizeDailySalesSelectedDateState)
  const searchParams: PrizeDailySalesSearchParams =
    useRecoilValue(filterAccordionSearchState)["prizeDailySalesSearchParams"] ??
    defaultSearchParams
  const { downloadCsv } = useDownloadCsv()
  const { isAdmin } = useUserRole()

  const {
    boothName,
    boothCategory,
    prizeCd,
    prizeName,
    pdpbRatio,
    machineInputDateRangeMethod,
    machineInputDateRange,
  } = searchParams

  // PDPB 関連の算出に必要なため、絞込み前のデータも取得する
  const prizeBoothSalesReturn = useResource({
    subject: "デイリー実績の取得",
    fetch: arcadeCd
      ? () =>
          getPrizeBoothSales(arcadeCd, { from: selectedDate, to: selectedDate })
      : undefined,
    recoilKey: `getPrizeBoothSales:${arcadeCd}:${selectedDate}:${selectedDate}`,
  }).resource
  const prizeBoothSales = useMemo(
    () => prizeBoothSalesReturn?.data.sales || [],
    [prizeBoothSalesReturn],
  )

  const searchedPrizeBoothSalesReturn = useResource({
    subject: "絞込み済みのデイリー実績の取得",
    fetch: arcadeCd
      ? () =>
          getPrizeBoothSales(arcadeCd, {
            from: selectedDate,
            to: selectedDate,
            boothName,
            boothCategory,
            prizeCd,
            prizeName,
          })
      : undefined,
    recoilKey: `getPrizeBoothSales:${arcadeCd}:${selectedDate}:${selectedDate}:${boothName}:${boothCategory}:${prizeCd}:${prizeName}`,
    skip: !!boothName && !!prizeCd && !!prizeName, // 絞込みが行われていない場合はスキップ
  }).resource
  const searchedPrizeBoothSales = useMemo(
    () => searchedPrizeBoothSalesReturn?.data.sales || [],
    [searchedPrizeBoothSalesReturn],
  )

  const prizePrizeDeliveriesReturn = useResource({
    subject: "デイリー入替計画の取得",
    fetch: arcadeCd
      ? () =>
          getPrizeDeliveries(arcadeCd, {
            from: formatApiDate(getMonthStart(selectedDate)),
            to: formatApiDate(getMonthEnd(selectedDate)),
          })
      : undefined,
    recoilKey: `getPrizeDailyPlans:${arcadeCd}:${selectedDate}:${selectedDate}`,
  }).resource
  const prizeDeliveries = useMemo(
    () => prizePrizeDeliveriesReturn?.data.deliveries || [],
    [prizePrizeDeliveriesReturn],
  )

  const prizeDailyPlansReturn = useResource({
    subject: "デイリー入替計画の取得",
    fetch: arcadeCd
      ? () =>
          getPrizeDailyPlans(arcadeCd, {
            from: selectedDate,
            to: selectedDate,
            boothName,
            boothCategory,
            prizeCd,
            prizeName,
          })
      : undefined,
    recoilKey: `getPrizeDailyPlans:${arcadeCd}:${selectedDate}:${selectedDate}:${boothName}:${boothCategory}:${prizeCd}:${prizeName}`,
  }).resource
  const prizeDailyPlans = useMemo(
    () => prizeDailyPlansReturn?.data.plans || [],
    [prizeDailyPlansReturn],
  )

  const sales = useMemo(() => {
    const filterFn = (sale: PrizeDailySale) => {
      if (machineInputDateRangeMethod === "specified") {
        if (sale.delivery?.machineInputDate === undefined) return false
        const isBetweenMachineInputDate = isBetweenDateRangeLabel(
          sale.delivery.machineInputDate,
          machineInputDateRange.start,
          machineInputDateRange.end,
        )
        if (!isBetweenMachineInputDate) return false
      }
      if (pdpbRatio !== undefined) {
        if (
          sale.pdpbRatio !== undefined &&
          sale.pdpbRatio * 100 >= pdpbRatio.min &&
          sale.pdpbRatio * 100 <= pdpbRatio.max
        ) {
          return true
        }
        return false
      }
      return searchedPrizeBoothSales
        .map((sale) => sale.booth.boothName)
        .includes(sale.booth.boothName)
    }

    return calcPrizeDailySales(
      prizeBoothSales,
      prizeDailyPlans,
      prizeDeliveries,
      filterFn,
      () => 0,
    )
  }, [
    prizeBoothSales,
    searchedPrizeBoothSales,
    prizeDailyPlans,
    prizeDeliveries,
    machineInputDateRangeMethod,
    machineInputDateRange,
    pdpbRatio,
  ])

  const outputCsv = useMemo(() => {
    const headerRow = {
      columns: [
        "プライズ機種名（ブース名）",
        "ブース区分",
        "景品CD",
        "景品名",
        "景品ステータス",
        "景品単価",
        "売上",
        "P/O",
        "対ブース平均",
        "投入可能日",
      ],
    }

    const rows = sales.items.map((item) => ({
      columns: [
        item.booth.boothName,
        item.plan?.boothCategory,
        item.prize?.prizeCd,
        item.prize?.prizeName,
        item.plan && item.plan.isPrizePlanChanged
          ? "当日更新"
          : item.delivery
            ? "新景品"
            : undefined,
        item.prize?.unitPriceJpy,
        item.sales,
        item.payoutRate,
        item.pdpbRatio,
        item.delivery?.machineInputDate,
      ].map((v) => (v === undefined ? "" : v.toString())),
    }))

    return { headerRow, rows }
  }, [sales])

  return (
    <Stack sx={{ gap: 2 }}>
      <Stack sx={{ gap: 1, width: "564px" }}>
        <Typography variant="h2">売上合計値</Typography>
        <Card sx={{ p: 2 }}>
          <Stack
            sx={{
              justifyContent: "space-between",
              flexDirection: "row",
              borderBottom: "1px solid",
              borderColor: "divider",
            }}
          >
            <Typography variant="subtitle1">売上</Typography>
            <Typography variant="subtitle1">
              {sales.total.sales?.toLocaleString() ?? 0}円
            </Typography>
          </Stack>
          <Stack
            sx={{
              justifyContent: "space-between",
              flexDirection: "row",
              borderBottom: "1px solid",
              borderColor: "divider",
            }}
          >
            <Typography variant="subtitle1">P/O</Typography>
            <Typography variant="subtitle1">
              {getRatioLabel(sales.total.payoutRate ?? 0)}
            </Typography>
          </Stack>
        </Card>
      </Stack>

      <Stack gap={1}>
        <Typography variant="h2">ブース別売上</Typography>
        <PrizeDailySalesTable sales={sales.items} />
      </Stack>

      <Button
        variant="contained"
        fullWidth
        onClick={() =>
          downloadCsv({
            csv: outputCsv,
            fileName: `prize_daily_sales_${arcadeCd}.csv`,
          })
        }
        disabled={!isAdmin}
      >
        CSVファイルに出力する
      </Button>
    </Stack>
  )
}

interface PrizeDailySalesTableProps {
  sales: PrizeDailySale[]
}

const PrizeDailySalesTable: React.FC<PrizeDailySalesTableProps> = ({
  sales,
}) => {
  const { arcadeCd } = useParams()
  const [salesAlertOpen, setSalesAlertOpen] = React.useState(false)
  const [pdpbRatioAlertOpen, setPdpbRatioAlertOpen] = React.useState(false)

  return (
    <Stack
      sx={{
        maxHeight: "80dvh",
      }}
    >
      <PaginatedTable
        scrollableX
        scrollableY
        stickyHeader
        noMargin
        items={sales}
        stateKey="prizeDailySalesTable"
        header={
          <TableHead>
            <TableRow
              sx={{
                th: {
                  py: 2,
                  px: 1,
                  whiteSpace: "nowrap",
                  textAlign: "center",
                },
              }}
            >
              <ExtTableCell border sticky zIndex={100} fixedWidth={240}>
                プライズ機種名（ブース名）
              </ExtTableCell>
              <ExtTableCell fixedWidth={200}>ブース区分</ExtTableCell>
              <ExtTableCell fixedWidth={160}>景品CD</ExtTableCell>
              <ExtTableCell fixedWidth={280}>景品名</ExtTableCell>
              <ExtTableCell fixedWidth={160}>景品ステータス</ExtTableCell>
              <ExtTableCell fixedWidth={160}>景品単価</ExtTableCell>
              <ExtTableCell fixedWidth={160}>売上</ExtTableCell>
              <ExtTableCell fixedWidth={160}>P/O</ExtTableCell>
              <ExtTableCell fixedWidth={160}>対ブース平均</ExtTableCell>
              <ExtTableCell fixedWidth={104}>投入可能日</ExtTableCell>
            </TableRow>
          </TableHead>
        }
        renderRow={(item, i) => {
          const isShowSalesAlert = item.sales === 0 || item.sales === undefined
          const isShowPdpbRatioAlert =
            item.pdpbRatio === undefined || item.pdpbRatio < 0.8
          return (
            <TableRow sx={{ td: { p: 2 } }} key={i}>
              <ExtTableCell border sticky zIndex={99}>
                <Link
                  component={RouterLink}
                  to={`/arcades/${arcadeCd}/prizes/sales/booth/${item.booth.boothName}`}
                  underline="none"
                >
                  <Typography variant="subtitle1">
                    {item.booth.boothName}
                  </Typography>
                </Link>
              </ExtTableCell>
              <ExtTableCell>{item.plan?.boothCategory ?? "-"}</ExtTableCell>
              <ExtTableCell>{item.prize?.prizeCd ?? "-"}</ExtTableCell>
              <ExtTableCell>{item.prize?.prizeName ?? "-"}</ExtTableCell>
              <ExtTableCell>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  {item.plan && item.plan.isPrizePlanChanged && (
                    <Chip label="当日更新" color="info" size="small" />
                  )}
                  {item.delivery && (
                    <Chip label="新景品" color="warning" size="small" />
                  )}
                </Stack>
              </ExtTableCell>
              <ExtTableCell sx={{ textAlign: "end" }}>
                {item.prize?.unitPriceJpy?.toLocaleString() || "-"}
              </ExtTableCell>
              <ExtTableCell>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="flex-end"
                  gap={1}
                  sx={{
                    color: isShowSalesAlert ? "warning.main" : undefined,
                  }}
                >
                  {isShowSalesAlert && (
                    <IconButton
                      sx={{ color: "warning.main" }}
                      onClick={() => setSalesAlertOpen(true)}
                    >
                      <WarningAmberIcon />
                    </IconButton>
                  )}
                  <Typography variant="body2">
                    {item.sales?.toLocaleString() ?? "-"}
                  </Typography>
                </Stack>
              </ExtTableCell>
              <ExtTableCell>
                <Typography variant="body2" sx={{ textAlign: "end" }}>
                  {getRatioLabel(item.payoutRate)}
                </Typography>
              </ExtTableCell>
              <ExtTableCell>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="flex-end"
                  gap={1}
                  sx={{
                    color: isShowPdpbRatioAlert ? "warning.main" : undefined,
                  }}
                >
                  {isShowPdpbRatioAlert && (
                    <IconButton
                      sx={{ color: "warning.main" }}
                      onClick={() => setPdpbRatioAlertOpen(true)}
                    >
                      <WarningAmberIcon />
                    </IconButton>
                  )}
                  <Typography variant="body2">
                    {getRatioLabel(item.pdpbRatio)}
                  </Typography>
                </Stack>
              </ExtTableCell>
              <ExtTableCell>
                {getJpDateLabel(item.delivery?.machineInputDate)}
              </ExtTableCell>
            </TableRow>
          )
        }}
      />
      <Dialog
        open={salesAlertOpen}
        onClose={() => setSalesAlertOpen(false)}
        maxWidth="sm"
        fullWidth
      >
        <Stack sx={{ p: 3, gap: 2 }}>
          <Typography variant="h2">売上アラート</Typography>
          <Typography>売上が0円です。</Typography>
          <Button
            onClick={() => setSalesAlertOpen(false)}
            variant="contained"
            fullWidth
            sx={{ mt: 1 }}
          >
            OK
          </Button>
        </Stack>
      </Dialog>
      <Dialog
        open={pdpbRatioAlertOpen}
        onClose={() => setPdpbRatioAlertOpen(false)}
        maxWidth="sm"
        fullWidth
      >
        <Stack sx={{ p: 3, gap: 2 }}>
          <Typography variant="h2">対ブース平均アラート</Typography>
          <Typography>対ブース平均が80%以下です。</Typography>
          <Button
            onClick={() => setPdpbRatioAlertOpen(false)}
            variant="contained"
            fullWidth
            sx={{ mt: 1 }}
          >
            OK
          </Button>
        </Stack>
      </Dialog>
    </Stack>
  )
}
