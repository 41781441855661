import { TextField } from "@mui/material"
import { useRecoilState } from "recoil"

import { FilterAccordion } from "src/components/molecules/FilterAccordion"
import { SearchAutoComplete } from "src/components/molecules/SearchAutoComplete"
import {
  materialPlacementTypeNames,
  materialPlacementTypes,
} from "src/domains/materials/materialInventoryHistoriesRepository"
import {
  InventoryMaterialPlacementsSearchParams,
  Placement,
} from "src/pages/materials/placements/InventoryMaterialPlacements"
import { inventoryMaterialPlacementsSearchParamsState } from "src/recoil/inventoryMaterials"
import { emptyValueNormalizedDeepEqual, generateFuzzyRegExp } from "src/utils"

export const InventoryMaterialPlacementsFilter: React.FC = () => {
  const [searchParams, setSearchParams] = useRecoilState(
    inventoryMaterialPlacementsSearchParamsState,
  )

  return (
    <FilterAccordion
      searchParams={searchParams}
      setSearchParams={setSearchParams}
      accordionLabel="絞り込み"
      defaultExpanded={!emptyValueNormalizedDeepEqual(searchParams, {})}
      formInputs={[
        {
          name: "placementType",
          label: "区分",
          render: ({ field, fieldState: { error } }) => (
            <SearchAutoComplete
              items={materialPlacementTypes.map((placementType) => ({
                label: materialPlacementTypeNames[placementType],
                value: placementType,
              }))}
              defaultValue={
                materialPlacementTypes.filter(
                  (placementType) =>
                    placementType === searchParams.placementType,
                )[0]
              }
              {...field}
              error={!!error}
              inputPlaceholder="棚／材料機械内"
            />
          ),
        },
        {
          name: "name",
          label: "名前",
          render: ({ field, fieldState: { error } }) => (
            <TextField {...field} error={!!error} helperText={error?.message} />
          ),
        },
      ]}
    />
  )
}

export const filterPlacements = (
  placements: Placement[],
  params: InventoryMaterialPlacementsSearchParams,
) => {
  const { name, placementType } = params

  return placements
    .filter((p) => (name ? generateFuzzyRegExp(name).test(p.name) : true))
    .filter((p) => (placementType ? p.placementType === placementType : true))
}
