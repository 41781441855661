import { TextField, MenuItem, Select } from "@mui/material"
import { useRecoilState } from "recoil"

import { FilterAccordion } from "src/components/molecules/FilterAccordion"
import { inventoryMaterialMachinesSearchParamsState } from "src/recoil/inventoryMaterials"
import { emptyValueNormalizedDeepEqual } from "src/utils"

export const InventoryMaterialMachinesFilter: React.FC = () => {
  const [searchParams, setSearchParams] = useRecoilState(
    inventoryMaterialMachinesSearchParamsState,
  )

  return (
    <FilterAccordion
      searchParams={searchParams}
      setSearchParams={setSearchParams}
      accordionLabel="絞り込み"
      defaultExpanded={!emptyValueNormalizedDeepEqual(searchParams, {})}
      formInputs={[
        {
          name: "amMachineNumber",
          label: "AM機器番号",
          render: ({ field, fieldState: { error } }) => (
            <TextField {...field} error={!!error} helperText={error?.message} />
          ),
        },
        {
          name: "isAvailable",
          label: "有効/無効",
          render: ({ field }) => (
            <Select
              {...field}
              value={field.value ?? ""}
              inputProps={{
                "aria-label": "isAvailable",
              }}
            >
              <MenuItem key="" value="" sx={{ height: 36 }} />
              <MenuItem value="true">有効</MenuItem>
              <MenuItem value="false">無効</MenuItem>
            </Select>
          ),
        },
        {
          name: "amMachineName",
          label: "AM機器名称",
          render: ({ field, fieldState: { error } }) => (
            <TextField {...field} error={!!error} helperText={error?.message} />
          ),
        },
      ]}
    />
  )
}
