import { TextField } from "@mui/material"
import { atom, useRecoilState } from "recoil"

import { FilterAccordion } from "src/components/molecules/FilterAccordion"

export const inventoryMaterialSearchParamsState =
  atom<InventoryMaterialSearchParams>({
    key: "inventoryMaterialSearchParamsState",
    default: {},
  })

export type InventoryMaterialSearchParams = {
  materialCd?: string
  materialName?: string
  materialNameKana?: string
  ipName?: string
  makerName?: string
}

export const InventoryMaterialFilter: React.FC = () => {
  const [searchParams, setSearchParams] = useRecoilState(
    inventoryMaterialSearchParamsState,
  )

  return (
    <FilterAccordion
      searchParams={searchParams}
      setSearchParams={setSearchParams}
      accordionLabel="絞り込み・並び替え"
      defaultExpanded={true}
      formInputs={[
        {
          name: "materialCd",
          label: "材料CD",
          render: ({ field, fieldState: { error } }) => (
            <TextField {...field} error={!!error} helperText={error?.message} />
          ),
        },
        {
          name: "materialName",
          label: "材料名",
          render: ({ field, fieldState: { error } }) => (
            <TextField {...field} error={!!error} helperText={error?.message} />
          ),
        },
        {
          name: "materialNameKana",
          label: "材料名カナ",
          render: ({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              placeholder="カタカナ部分一致"
              error={!!error}
              helperText={error?.message}
            />
          ),
        },
        {
          name: "makerName",
          label: "メーカー名",
          render: ({ field, fieldState: { error } }) => (
            <TextField {...field} error={!!error} helperText={error?.message} />
          ),
        },
        {
          name: "ipName",
          label: "IP名",
          render: ({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              placeholder="カタカナ部分一致"
              error={!!error}
              helperText={error?.message}
            />
          ),
        },
      ]}
    />
  )
}
