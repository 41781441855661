import React, { SetStateAction, useState } from "react"

import {
  Typography,
  Stack,
  TableHead,
  TableRow,
  Link,
  Card,
  TextField,
  Button,
  Dialog,
} from "@mui/material"
import { Link as RouterLink, useNavigate, useParams } from "react-router-dom"
import { useRecoilState, useRecoilValue } from "recoil"

import {
  ExecutionPeriod,
  Prize,
  PrizeInventoryEstimation,
} from "src/api/models"
import {
  deletePrizeInventoryEstimationAdjustment,
  getPrizeInventoryEstimations,
  putPrizeInventoryEstimationAdjustment,
} from "src/api/prize-inventory-estimations"
import { ExtTableCell } from "src/components/atoms/ExtTableCell"
import { FilterAccordion } from "src/components/molecules/FilterAccordion"
import { PaginatedTable } from "src/components/organisms/PaginatedTable"
import { MainContentLayout } from "src/components/templates/MainContentLayout"
import { useResource } from "src/hooks/useResource"
import { useSubmitting } from "src/hooks/useSubmitting"
import {
  currentInventoryPeriodState,
  filterAccordionSearchState,
} from "src/recoil"
import { emptyValueNormalizedDeepEqual } from "src/utils"

export const InventoryPrizeEstimationsAdjust: React.FC = () => {
  return (
    <MainContentLayout
      title="棚卸カウント無し最終調整"
      renderFilter={() => <InventoryPrizeEstimationsAdjustFilter />}
      renderContent={() => <InventoryPrizeEstimationsAdjustInner />}
    />
  )
}

type InventoryPrizeEstimationsAdjustSearchParams = {
  prizeName?: string
  prizeCd?: string
  prizeNameKana?: string // Prize["prizeNameKana"]
  makerName?: Prize["makerName"]
  ipName?: Prize["ipName"]
}

const defaultSearchParams: InventoryPrizeEstimationsAdjustSearchParams = {}

const InventoryPrizeEstimationsAdjustFilter: React.FC = () => {
  const [recoilSearchParams, setRecoilSearchParams] = useRecoilState(
    filterAccordionSearchState,
  )

  const searchParams: InventoryPrizeEstimationsAdjustSearchParams =
    recoilSearchParams["inventoryPrizeEstimationAdjustSearchParams"] ??
    defaultSearchParams
  const setSearchParams = (
    params: SetStateAction<InventoryPrizeEstimationsAdjustSearchParams>,
  ) =>
    setRecoilSearchParams((prev) => ({
      ...prev,
      inventoryPrizeEstimationAdjustSearchParams: params,
    }))

  return (
    <FilterAccordion
      searchParams={searchParams}
      setSearchParams={setSearchParams}
      accordionLabel="絞り込み"
      defaultExpanded={
        !emptyValueNormalizedDeepEqual(searchParams, defaultSearchParams)
      }
      formInputs={[
        {
          name: "prizeName",
          label: "景品名",
          render: ({ field, fieldState: { error } }) => (
            <TextField {...field} error={!!error} fullWidth />
          ),
        },
        {
          name: "prizeCd",
          label: "景品CD",
          render: ({ field, fieldState: { error } }) => (
            <TextField {...field} error={!!error} fullWidth />
          ),
        },
        {
          name: "prizeNameKana",
          label: "景品名カナ",
          render: ({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              error={!!error}
              fullWidth
              placeholder="カタカナ部分一致"
            />
          ),
        },
        {
          name: "makerName",
          label: "メーカー名",
          render: ({ field, fieldState: { error } }) => (
            <TextField {...field} error={!!error} />
          ),
        },
        {
          name: "ipName",
          label: "IP名",
          render: ({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              error={!!error}
              placeholder="カタカナ部分一致"
            />
          ),
        },
      ]}
    />
  )
}

const InventoryPrizeEstimationsAdjustInner: React.FC = () => {
  const { arcadeCd } = useParams()
  const searchParams: InventoryPrizeEstimationsAdjustSearchParams =
    useRecoilValue(filterAccordionSearchState)[
      "inventoryPrizeEstimationAdjustSearchParams"
    ] ?? defaultSearchParams

  const { prizeCd, prizeName, prizeNameKana, ipName, makerName } = searchParams

  const currentInventoryPeriod = useRecoilValue(currentInventoryPeriodState)
  const { resource: prizeInventoryEstimations, refetch: refetchEstimations } =
    useResource({
      subject: "棚卸推定差分一覧の取得",
      fetch:
        arcadeCd && currentInventoryPeriod
          ? () =>
              getPrizeInventoryEstimations(
                arcadeCd,
                currentInventoryPeriod.id,
                {
                  prizeCd,
                  prizeName,
                  prizeNameKana,
                  ipName,
                  makerName,
                },
              )
          : undefined,
      recoilKey: `getPrizeInventoryEstimations:${arcadeCd}:${prizeCd}:${prizeName}:${prizeNameKana}:${ipName}:${makerName}`,
    })
  const { estimations, totalAdjustedPayoutPrices, totalLogicalPayoutPrices } =
    prizeInventoryEstimations?.data ?? {}

  const [isExecuteDialogOpen, setIsExecuteDialogOpen] = useState(false)

  return (
    <Stack sx={{ gap: 2 }}>
      <Stack>
        <InventoryPrizeEstimationsAdjustTable
          estimations={estimations ?? []}
          currentInventoryPeriod={currentInventoryPeriod}
          refetchEstimations={refetchEstimations}
        />
      </Stack>

      <Stack sx={{ gap: 1 }}>
        <Card sx={{ p: 2 }}>
          <Stack
            sx={{
              justifyContent: "space-between",
              flexDirection: "row",
              borderBottom: "1px solid",
              borderColor: "divider",
            }}
          >
            <Typography variant="subtitle1">最終景品代</Typography>
            <Typography variant="subtitle1">
              {totalAdjustedPayoutPrices} 円
            </Typography>
          </Stack>
          <Stack
            sx={{
              justifyContent: "space-between",
              flexDirection: "row",
              borderBottom: "1px solid",
              borderColor: "divider",
            }}
          >
            <Typography variant="subtitle1">GiGO NAVI景品代</Typography>
            <Typography variant="subtitle1">
              {totalLogicalPayoutPrices} 円
            </Typography>
          </Stack>
        </Card>
      </Stack>

      <Button
        variant="contained"
        onClick={() => {
          setIsExecuteDialogOpen(true)
        }}
        fullWidth
      >
        棚卸カウント無し処理を確定する
      </Button>

      <Dialog
        open={isExecuteDialogOpen}
        onClose={() => setIsExecuteDialogOpen(false)}
        maxWidth="sm"
        fullWidth
      >
        <Stack sx={{ p: 3, gap: 2 }}>
          <Typography variant="h2">棚卸カウント無し処理の確認</Typography>

          <Typography variant="body2" color="error.main" fontWeight="bold">
            <p>
              景品確定処理を行うと以下の動作が行われます。問題ないことを確認して確定ボタンを押してください。
            </p>
            <ul style={{ paddingLeft: "1em" }}>
              <li>
                今回のGiGO NAVI上の景品棚卸データに変更が加えられなくなります
              </li>
              <li>棚／ブース／景品についた「済」マークが全て消えます</li>
            </ul>
          </Typography>

          <Stack sx={{ gap: 1 }}>
            <Button
              onClick={() => setIsExecuteDialogOpen(false)}
              variant="contained"
              fullWidth
              sx={{ mt: 1 }}
              color="error"
            >
              棚卸カウント無し処理をする
            </Button>
            <Button
              onClick={() => setIsExecuteDialogOpen(false)}
              variant="outlined"
              fullWidth
              sx={{ mt: 1 }}
            >
              キャンセル
            </Button>
          </Stack>
        </Stack>
      </Dialog>
    </Stack>
  )
}

interface InventoryPrizeEstimationsAdjustTableProps {
  estimations: PrizeInventoryEstimation[]
  currentInventoryPeriod?: ExecutionPeriod
  refetchEstimations: () => void
}

const InventoryPrizeEstimationsAdjustTable: React.FC<
  InventoryPrizeEstimationsAdjustTableProps
> = ({ estimations, currentInventoryPeriod, refetchEstimations }) => {
  const { arcadeCd } = useParams()
  const navigate = useNavigate()

  const [adjustDialogEstimation, setAdjustDialogEstimation] = useState<
    PrizeInventoryEstimation | undefined
  >(undefined)
  const [restoreDialogEstimation, setRestoreDialogEstimation] = useState<
    PrizeInventoryEstimation | undefined
  >(undefined)

  const { submitPromises } = useSubmitting()
  const onAdjust = async (prizeCd: string) => {
    if (!arcadeCd || !currentInventoryPeriod) return
    await submitPromises([
      {
        subject: "棚卸推定の払出個数の調整",
        showSuccessMessage: false,
        promise: async () => {
          await putPrizeInventoryEstimationAdjustment(
            arcadeCd,
            currentInventoryPeriod.id,
            prizeCd,
          )
        },
      },
    ])
  }
  const onRestore = async (prizeCd: string) => {
    if (!arcadeCd || !currentInventoryPeriod) return
    await submitPromises([
      {
        subject: "棚卸推定の払出個数の調整の削除",
        showSuccessMessage: false,
        promise: async () => {
          await deletePrizeInventoryEstimationAdjustment(
            arcadeCd,
            currentInventoryPeriod.id,
            prizeCd,
          )
        },
      },
    ])
  }

  return (
    <Stack
      sx={{
        maxHeight: "calc(100dvh - 380px)",
      }}
    >
      <PaginatedTable
        scrollableX
        scrollableY
        stickyHeader
        noMargin
        items={estimations}
        stateKey="inventoryPrizeEstimationAdjustTable"
        header={
          <TableHead>
            <TableRow
              sx={{
                th: {
                  py: 2,
                  px: 1,
                  whiteSpace: "nowrap",
                  textAlign: "center",
                },
              }}
            >
              <ExtTableCell border sticky zIndex={100} fixedWidth={240}>
                景品名
              </ExtTableCell>
              <ExtTableCell fixedWidth={160}>景品CD</ExtTableCell>
              <ExtTableCell fixedWidth={120}>
                SEAMSデータ個数
                <br />
                （初期数）
              </ExtTableCell>
              <ExtTableCell fixedWidth={120}>
                GiGO NAVI
                <br />
                理論差異
              </ExtTableCell>
              <ExtTableCell fixedWidth={120}>
                最終差異
                <br />
                （最終処理数）
              </ExtTableCell>
              <ExtTableCell fixedWidth={120}>最終残数</ExtTableCell>
              <ExtTableCell fixedWidth={180}>消費の調整</ExtTableCell>
            </TableRow>
          </TableHead>
        }
        renderRow={(item, i) => (
          <TableRow sx={{ td: { p: 2 } }} key={i}>
            <ExtTableCell border sticky zIndex={99}>
              <Link
                component={RouterLink}
                to={`/arcades/${arcadeCd}/prizes/${item.prizeCd}`}
                underline="none"
              >
                <Typography variant="subtitle1">{item.prizeName}</Typography>
              </Link>
            </ExtTableCell>
            <ExtTableCell sx={{ textAlign: "center" }}>
              {item.prizeCdsInSeams}
            </ExtTableCell>
            <ExtTableCell sx={{ textAlign: "right" }}>
              {item.importedStock ?? "-"}
            </ExtTableCell>
            <ExtTableCell sx={{ textAlign: "right" }}>
              {item.payout ?? "-"}
            </ExtTableCell>
            <ExtTableCell sx={{ textAlign: "right" }}>
              {item.adjustedPayout ?? "-"}
            </ExtTableCell>
            <ExtTableCell sx={{ textAlign: "right" }}>
              {item.adjustedStock ?? "-"}
            </ExtTableCell>
            <ExtTableCell>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="center"
              >
                {item.adjustedStock !== undefined ? (
                  item.adjustedStock > 0 ? (
                    <Button
                      variant="contained"
                      onClick={() => {
                        setAdjustDialogEstimation(item)
                      }}
                      size="small"
                    >
                      全て消費する
                    </Button>
                  ) : (
                    <Button
                      variant="outlined"
                      onClick={() => {
                        setRestoreDialogEstimation(item)
                      }}
                      size="small"
                    >
                      元に戻す
                    </Button>
                  )
                ) : (
                  <></>
                )}
              </Stack>
            </ExtTableCell>
          </TableRow>
        )}
      />
      {adjustDialogEstimation && (
        <AllPayoutEstimationDialog
          isOpen={!!adjustDialogEstimation}
          estimation={adjustDialogEstimation}
          onClose={() => setAdjustDialogEstimation(undefined)}
          onExecuted={(prizeCd) => {
            onAdjust(prizeCd)
            setAdjustDialogEstimation(undefined)
            refetchEstimations()
          }}
        />
      )}
      {restoreDialogEstimation && (
        <RestoreEstimationDialog
          isOpen={!!restoreDialogEstimation}
          estimation={restoreDialogEstimation}
          onClose={() => setRestoreDialogEstimation(undefined)}
          onExecuted={(prizeCd) => {
            onRestore(prizeCd)
            setRestoreDialogEstimation(undefined)
            refetchEstimations()
          }}
        />
      )}
    </Stack>
  )
}

interface AllPayoutEstimationDialogProps {
  isOpen: boolean
  estimation: PrizeInventoryEstimation
  onClose: () => void
  onExecuted: (prizeCd: string) => void
}

const AllPayoutEstimationDialog: React.FC<AllPayoutEstimationDialogProps> = ({
  isOpen,
  estimation,
  onClose,
  onExecuted,
}) => {
  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="sm" fullWidth>
      <Stack sx={{ p: 3, gap: 2 }}>
        <Typography variant="h2">本当に全て消費しますか？</Typography>
        <Typography variant="h3">プライズ名: {estimation.prizeName}</Typography>
        <Stack direction="row" gap={2}>
          <Stack flex={1}>
            <Typography variant="body2" color="gray.50">
              SEAMSデータ個数（初期数）
            </Typography>
            <Typography>
              <Typography variant="h3" component="span" sx={{ pr: 0.5 }}>
                {estimation.importedStock}
              </Typography>
              個
            </Typography>
          </Stack>
          <Stack flex={1}>
            <Typography variant="body2" color="gray.50">
              GiGO NAVI理論差異
            </Typography>
            <Typography>
              <Typography variant="h3" component="span" sx={{ pr: 0.5 }}>
                {estimation.estimatedPayout}
              </Typography>
              個
            </Typography>
          </Stack>
        </Stack>
        <Stack direction="row" gap={2}>
          <Stack flex={1}>
            <Typography variant="body2" color="gray.50">
              最終差異 （最終処理数）
            </Typography>
            <Typography>
              <Typography variant="h3" component="span" sx={{ pr: 0.5 }}>
                {estimation.adjustedPayout}
              </Typography>
              個
            </Typography>
          </Stack>
          <Stack flex={1}>
            <Typography variant="body2" color="gray.50">
              最終残数
            </Typography>
            <Typography>
              <Typography variant="h3" component="span" sx={{ pr: 0.5 }}>
                {estimation.adjustedStock}
              </Typography>
              個
            </Typography>
          </Stack>
        </Stack>
        <Stack sx={{ gap: 1 }}>
          <Button
            onClick={() => {
              onExecuted(estimation.prizeCd)
            }}
            variant="contained"
            fullWidth
            sx={{ mt: 1 }}
          >
            実行する
          </Button>
          <Button onClick={onClose} variant="outlined" fullWidth sx={{ mt: 1 }}>
            キャンセル
          </Button>
        </Stack>
      </Stack>
    </Dialog>
  )
}

interface RestoreEstimationDialogProps {
  isOpen: boolean
  estimation: PrizeInventoryEstimation
  onClose: () => void
  onExecuted: (prizeCd: string) => void
}

const RestoreEstimationDialog: React.FC<RestoreEstimationDialogProps> = ({
  isOpen,
  estimation,
  onClose,
  onExecuted,
}) => {
  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="sm" fullWidth>
      <Stack sx={{ p: 3, gap: 2 }}>
        <Typography variant="h2">消費を元に戻しますか？</Typography>
        <Typography variant="h3">プライズ名: {estimation.prizeName}</Typography>
        <Stack direction="row" gap={2}>
          <Stack flex={1}>
            <Typography variant="body2" color="gray.50">
              SEAMSデータ個数（初期数）
            </Typography>
            <Typography>
              <Typography variant="h3" component="span" sx={{ pr: 0.5 }}>
                {estimation.importedStock}
              </Typography>
              個
            </Typography>
          </Stack>
          <Stack flex={1}>
            <Typography variant="body2" color="gray.50">
              GiGO NAVI理論差異
            </Typography>
            <Typography>
              <Typography variant="h3" component="span" sx={{ pr: 0.5 }}>
                {estimation.estimatedPayout}
              </Typography>
              個
            </Typography>
          </Stack>
        </Stack>
        <Stack direction="row" gap={2}>
          <Stack flex={1}>
            <Typography variant="body2" color="gray.50">
              最終差異 （最終処理数）
            </Typography>
            <Typography>
              <Typography variant="h3" component="span" sx={{ pr: 0.5 }}>
                {estimation.adjustedPayout}
              </Typography>
              個
            </Typography>
          </Stack>
          <Stack flex={1}>
            <Typography variant="body2" color="gray.50">
              最終残数
            </Typography>
            <Typography>
              <Typography variant="h3" component="span" sx={{ pr: 0.5 }}>
                {estimation.adjustedStock}
              </Typography>
              個
            </Typography>
          </Stack>
        </Stack>
        <Stack sx={{ gap: 1 }}>
          <Button
            onClick={() => {
              onExecuted(estimation.prizeCd)
            }}
            variant="contained"
            fullWidth
            sx={{ mt: 1 }}
          >
            実行する
          </Button>
          <Button onClick={onClose} variant="outlined" fullWidth sx={{ mt: 1 }}>
            キャンセル
          </Button>
        </Stack>
      </Stack>
    </Dialog>
  )
}
