import { MenuItem, Select, TextField } from "@mui/material"
import { atom, useRecoilState } from "recoil"

import { Prize } from "src/api/models"
import { FilterAccordion } from "src/components/molecules/FilterAccordion"
import {
  PrizesSearchSortBy,
  prizesSearchSortByLabels,
} from "src/domains/prizes/prizeRepository"
import { emptyValueNormalizedDeepEqual } from "src/utils"

export type PrizesSearchParams = {
  prizeCd?: Prize["prizeCd"]
  prizeName?: Prize["prizeName"]
  prizeNameKana?: string
  makerName?: Prize["makerName"]
  ipName?: Prize["ipName"]
  sortBy: "" | PrizesSearchSortBy
}

const defaultSearchParams: PrizesSearchParams = {
  prizeCd: "",
  prizeName: "",
  prizeNameKana: "",
  makerName: "",
  ipName: "",
  sortBy: "",
}

export const prizeSearchParamsState = atom<PrizesSearchParams>({
  key: "prizeSearchParamsState",
  default: defaultSearchParams,
})

export const PrizeFilter: React.FC = () => {
  const [searchParams, setSearchParams] = useRecoilState(prizeSearchParamsState)

  return (
    <FilterAccordion
      searchParams={searchParams}
      setSearchParams={setSearchParams}
      accordionLabel="絞り込み・並び替え"
      defaultExpanded={
        !emptyValueNormalizedDeepEqual(searchParams, defaultSearchParams)
      }
      formInputs={[
        {
          name: "prizeCd",
          label: "景品CD",
          render: ({ field, fieldState: { error } }) => (
            <TextField {...field} error={!!error} />
          ),
        },
        {
          name: "prizeName",
          label: "景品名",
          render: ({ field, fieldState: { error } }) => (
            <TextField {...field} error={!!error} />
          ),
        },
        {
          name: "prizeNameKana",
          label: "景品名カナ",
          render: ({ field, fieldState: { error } }) => (
            <TextField {...field} error={!!error} />
          ),
        },
        {
          name: "makerName",
          label: "メーカー名",
          render: ({ field, fieldState: { error } }) => (
            <TextField id={field.name} {...field} error={!!error} />
          ),
        },
        {
          name: "ipName",
          label: "IP名",
          render: ({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              error={!!error}
              placeholder="カタカナ部分一致"
            />
          ),
        },

        {
          name: "sortBy",
          label: "並び替え",
          render: ({ field }) => (
            <Select {...field} value={field.value ?? ""}>
              <MenuItem key="" value="" sx={{ height: 36 }} />
              {Object.entries(prizesSearchSortByLabels).map(([key, value]) => (
                <MenuItem key={key} value={key}>
                  {value}
                </MenuItem>
              ))}
            </Select>
          ),
        },
      ]}
    />
  )
}
